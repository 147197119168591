<template>
  <v-row>
    <v-col>
      <v-card
        :loading="
          allVerify.length == 0 &&
          allVerified.length == 0 &&
          allPending.length == 0
        "
        :disabled="
          allVerify.length == 0 &&
          allVerified.length == 0 &&
          allPending.length == 0
        "
      >
        <v-card-title>
          Summary
          <v-icon right>mdi-clipboard-text</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card dark color="orange accent-3">
                <v-card-title>
                  MEMBERS NEEDS TO VERIFY
                  <v-spacer></v-spacer>
                  <v-avatar color="white">
                    <v-icon color="orange accent-3">mdi-account-alert</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text>
                  <span class="text-h1 font-weight-bold white--text"
                    >{{ allVerify.length }} Member</span
                  >
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    block
                    text
                    @click="tables.verify.show = !tables.verify.show"
                  >
                    <span>{{
                      tables.verify.show == true ? "close" : "details"
                    }}</span>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="tables.verify.show">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-data-table
                        :headers="tables.verify.headers"
                        :items="allVerify"
                      ></v-data-table>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>

            <v-col>
              <v-card dark color="success">
                <v-card-title>
                  VERIFIED MEMBERS
                  <v-spacer></v-spacer>
                  <v-avatar color="white">
                    <v-icon color="success">mdi-account-check</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text>
                  <span class="text-h1 font-weight-bold white--text"
                    >{{ allVerified.length }} Member</span
                  >
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    block
                    text
                    @click="tables.verified.show = !tables.verified.show"
                  >
                    <span>{{
                      tables.verified.show == true ? "close" : "details"
                    }}</span>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="tables.verified.show">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-data-table
                        :headers="tables.verified.headers"
                        :items="allVerified"
                      ></v-data-table>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>

            <v-col>
              <v-card dark color="warning">
                <v-card-title>
                  PENDING VERIFICATION
                  <v-spacer></v-spacer>
                  <v-avatar color="white">
                    <v-icon color="warning">mdi-account-clock</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text>
                  <span class="text-h1 font-weight-bold white--text"
                    >{{ allPending.length }} Member</span
                  >
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    block
                    text
                    @click="tables.pending.show = !tables.pending.show"
                  >
                    <span>{{
                      tables.pending.show == true ? "close" : "details"
                    }}</span>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="tables.pending.show">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-data-table
                        :headers="tables.pending.headers"
                        :items="allPending"
                      ></v-data-table>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      tables: {
        verify: {
          show: false,
          headers: [
            { text: "Session ID", value: "session_id" },
            { text: "Member ID", value: "website_id" },
            { text: "Member Name", value: "name" },
            { text: "Agent", value: "agent.name" },
          ],
        },
        verified: {
          show: false,
          headers: [
            { text: "Session ID", value: "session_id" },
            { text: "Member ID", value: "website_id" },
            { text: "Member Name", value: "name" },
            { text: "Agent", value: "agent.name" },
          ],
        },
        pending: {
          show: false,
          headers: [
            { text: "Session ID", value: "session_id" },
            { text: "Member ID", value: "website_id" },
            { text: "Member Name", value: "name" },
            { text: "Agent", value: "agent.name" },
          ],
        },
      },
    };
  },
  methods: {
    ...mapActions(["fetchVerify", "fetchVerified", "fetchPending"]),
  },
  computed: {
    ...mapGetters(["allVerify", "allVerified", "allPending"]),
  },
  mounted(){
    this.fetchVerify();
    this.fetchVerified();
    this.fetchPending();
  }
};
</script>